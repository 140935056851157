@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700");
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  scrollbar-gutter: stable;
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 1rem 1.5rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #d3d3d3;
  cursor: pointer;
}

.custom-select {
  position: relative;
  min-width: 350px;
}
.custom-select select {
  appearance: none;
  /*  safari  */
  -webkit-appearance: none;
  /*  other styles for aesthetics */
  width: 100%;
  padding-right: 6rem;
}
.custom-select::before, .custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}
.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid #0a0a0a;
  top: 40%;
}
.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid #0a0a0a;
  top: 55%;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper .ba-form-page {
  padding: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.com-baforms-wrapper .ba-form-page .ba-field-label-wrapper {
  display: none;
}
.com-baforms-wrapper .ba-form-page .show-label .ba-field-label-wrapper {
  display: block;
}
.com-baforms-wrapper .ba-form-page fieldset {
  padding: 0;
  border: none;
}
.com-baforms-wrapper .ba-form-page button::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page input::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page optgroup::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page select::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page textarea::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.com-baforms-wrapper .ba-form-page .ba-form-page-navigation-title,
.com-baforms-wrapper .ba-form-page .upload-file-btn,
.com-baforms-wrapper .ba-form-page .upload-file-input,
.com-baforms-wrapper .ba-form-page .ba-field-container select,
.com-baforms-wrapper .ba-form-page .ba-field-container textarea[readonly],
.com-baforms-wrapper .ba-form-page .ba-field-container textarea,
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=text],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=number],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=password],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=email],
.com-baforms-wrapper .ba-form-page .ba-field-container select option,
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: 400;
  font-size: 1rem !important;
  color: #0a0a0a !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #ccb46c;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #fbfbfb;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 95%;
  line-height: 1.5;
  color: #0a0a0a;
  font-weight: 400;
  background: #ffffff;
}

main,
details {
  display: block;
}

p,
li {
  margin-bottom: 1rem;
}

a {
  color: #ccb46c;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}
a:hover {
  color: #0a0a0a;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

big,
.big {
  font-size: 1.1875rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

ul,
ol {
  padding-left: 40px;
}
ul li,
ol li {
  padding-left: 0.5rem;
}
ul li::marker,
ol li::marker {
  font-weight: bold;
  color: #ccb46c;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0.5rem 0 1.5rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  font-weight: 700;
  text-transform: capitalize;
}

h1,
.h1 {
  font-size: 2rem;
}
h1--mobile,
.h1--mobile {
  font-size: 2rem;
}
h1--tablet,
.h1--tablet {
  font-size: 2.2rem;
}
@media (min-width: 576px) {
  h1,
  .h1 {
    font-size: 2.2rem;
  }
}
@media (min-width: 960px) {
  h1,
  .h1 {
    font-size: 2.4rem;
  }
}

h2,
.h2 {
  font-size: 1.8rem;
}
h2--mobile,
.h2--mobile {
  font-size: 1.8rem;
}
h2--tablet,
.h2--tablet {
  font-size: 2rem;
}
@media (min-width: 576px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}
@media (min-width: 960px) {
  h2,
  .h2 {
    font-size: 2.2rem;
  }
}

h3,
.h3 {
  font-size: 1.6rem;
}
h3--mobile,
.h3--mobile {
  font-size: 1.6rem;
}
h3--tablet,
.h3--tablet {
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  h3,
  .h3 {
    font-size: 1.8rem;
  }
}
@media (min-width: 960px) {
  h3,
  .h3 {
    font-size: 2rem;
  }
}

h4,
.h4 {
  font-size: 1.4rem;
}
h4--mobile,
.h4--mobile {
  font-size: 1.4rem;
}
h4--tablet,
.h4--tablet {
  font-size: 1.6rem;
}
@media (min-width: 576px) {
  h4,
  .h4 {
    font-size: 1.6rem;
  }
}
@media (min-width: 960px) {
  h4,
  .h4 {
    font-size: 1.8rem;
  }
}

h5,
.h5 {
  font-size: 1.2rem;
}
h5--mobile,
.h5--mobile {
  font-size: 1.2rem;
}
h5--tablet,
.h5--tablet {
  font-size: 1.4rem;
}
@media (min-width: 576px) {
  h5,
  .h5 {
    font-size: 1.4rem;
  }
}
@media (min-width: 960px) {
  h5,
  .h5 {
    font-size: 1.6rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
}
h6--mobile,
.h6--mobile {
  font-size: 1rem;
}
h6--tablet,
.h6--tablet {
  font-size: 1.2rem;
}
@media (min-width: 576px) {
  h6,
  .h6 {
    font-size: 1.2rem;
  }
}
@media (min-width: 960px) {
  h6,
  .h6 {
    font-size: 1.4rem;
  }
}

.subheading,
.small-heading {
  margin-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #ccb46c;
  font-weight: 600;
}
.subheading--rotate,
.small-heading--rotate {
  rotate: 90deg;
}

.small-heading {
  color: #009bb7;
}

.large-heading {
  font-size: 3rem;
  text-transform: uppercase;
}

.link-with-icon svg {
  margin-left: 0.5rem;
}
.link-with-icon svg * {
  fill: #ccb46c;
  transition: 500ms ease-in-out;
}
.link-with-icon:hover svg * {
  fill: #009bb7;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button],
.com-baforms-wrapper .ba-form-submit-btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  background: #009bb7;
  background-size: 200% 100%;
  background-position: 100% 0;
  border: 0;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  letter-spacing: 1px;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
  -webkit-appearance: none;
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
.com-baforms-wrapper .ba-form-submit-btn:hover {
  color: #ffffff;
  background-position: 0 0;
  border: 0;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active,
.com-baforms-wrapper .ba-form-submit-btn:active {
  border: 0;
}
.button:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited,
.com-baforms-wrapper .ba-form-submit-btn:visited {
  color: #ffffff;
}

.button--full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button--secondary {
  background: linear-gradient(120deg, #006ba6, #ccb46c);
  background-size: 200% 100%;
  background-position: 100% 0;
}
.button--1 {
  background: #009bb7;
}
.button--1:hover {
  background: #00cff4;
}
.button--2 {
  background: #ccb46c;
}
.button--2:hover {
  background: #ba9c41;
}
.button--3 {
  background: #006ba6;
}
.button--3:hover {
  background: #004469;
}
.button--outline, .button--outline-2, .button--outline-3, .button--outline-4, .button--outline-white, .button--outline-black {
  background: transparent;
  color: #009bb7;
  box-shadow: inset 0 0 0 3px #009bb7;
}
.button--outline:hover, .button--outline-2:hover, .button--outline-3:hover, .button--outline-4:hover, .button--outline-white:hover, .button--outline-black:hover {
  color: #ffffff;
  background: #00cff4;
  box-shadow: inset 0 0 0 3px #009bb7;
}
.button--outline-2 {
  box-shadow: inset 0 0 0 3px #ccb46c;
}
.button--outline-2:hover {
  color: #ffffff;
  background: #dccb99;
  box-shadow: inset 0 0 0 3px #ba9c41;
}
.button--outline-3 {
  box-shadow: inset 0 0 0 3px #006ba6;
}
.button--outline-3:hover {
  color: #ffffff;
  background: #0092e3;
  box-shadow: inset 0 0 0 3px #004469;
}
.button--outline-white {
  box-shadow: inset 0 0 0 3px #ffffff;
  color: #ffffff;
}
.button--outline-white:hover {
  color: #009bb7;
  background: #ffffff;
  box-shadow: inset 0 0 0 3px #ffffff;
}
.button--outline-black {
  box-shadow: inset 0 0 0 3px #0a0a0a;
  color: #0a0a0a;
}
.button--outline-black:hover {
  color: #ffffff;
  background: #0a0a0a;
  box-shadow: inset 0 0 0 3px #0a0a0a;
}
.button--white, .button--white-2, .button--white-3, .button--white-4 {
  color: #009bb7;
  background: #ffffff;
}
.button--white:visited, .button--white-2:visited, .button--white-3:visited, .button--white-4:visited {
  color: #009bb7;
}
.button--white:hover, .button--white-2:hover, .button--white-3:hover, .button--white-4:hover {
  color: #ffffff;
  background: #009bb7;
}
.button--white-2:hover {
  background: #ccb46c;
}
.button--white-3:hover {
  background: #006ba6;
}
.button--black, .button--black-2, .button--black-3, .button--black-4 {
  color: #ffffff;
  background: #0a0a0a;
}
.button--black:hover, .button--black-2:hover, .button--black-3:hover, .button--black-4:hover {
  color: #ffffff;
  background: #9e9e9e;
}
.button--black-2:hover {
  background: #ccb46c;
}
.button--black-3:hover {
  background: #006ba6;
}
.button--arrow svg {
  margin-left: 0.5rem;
}
.button-wrap--center {
  text-align: center;
}
.button--align-right {
  float: right;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

@media (min-width: 480px) {
  .hide-smedium {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hide-medium {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-mlarge {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .hide-large {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-xlarge {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .hide-xxlarge {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .hide-xxxlarge {
    display: none !important;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.primary-colour {
  color: #009bb7;
}

.secondary-colour {
  color: #ccb46c;
}

.tertiary-colour {
  color: #006ba6;
}

.light-grey-colour {
  color: #dcdbdb;
}

.medium-grey-colour {
  color: #d3d3d3;
}

.dark-grey-colour {
  color: #9e9e9e;
}

.black-colour {
  color: #0a0a0a;
}

.white-colour {
  color: #ffffff;
}

.off-white-colour {
  color: #fbfbfb;
}

.success-colour {
  color: #3adb76;
}

.warning-colour {
  color: #ffae00;
}

.alert-colour {
  color: #cc4b37;
}

.primary-background {
  background-color: #009bb7;
}

.secondary-background {
  background-color: #ccb46c;
}

.tertiary-background {
  background-color: #006ba6;
}

.light-grey-background {
  background-color: #dcdbdb;
}

.medium-grey-background {
  background-color: #d3d3d3;
}

.dark-grey-background {
  background-color: #9e9e9e;
}

.black-background {
  background-color: #0a0a0a;
}

.white-background {
  background-color: #ffffff;
}

.off-white-background {
  background-color: #fbfbfb;
}

.success-background {
  background-color: #3adb76;
}

.warning-background {
  background-color: #ffae00;
}

.alert-background {
  background-color: #cc4b37;
}

.colour-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 40px;
}

.colour-wrapper {
  margin-right: 30px;
}
.colour-wrapper div {
  width: 75px;
  height: 75px;
  margin-bottom: 0.5rem;
}

.animation-wrapper div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1.4rem;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.8.1
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*$breakpoints: (
  "small": 0,
  "medium": 640px,
  "large": 1024px,
  "xlarge": 1200px,
  "xxlarge": 1440px,
) !default;*/
/*$breakpoints: (
  "small": 0,
  "medium": 640px,
  "large": 1024px,
  "xlarge": 1200px,
  "xxlarge": 1440px,
) !default;*/
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 36em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 112.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns, .newslist .row.collapse > .latestnews-item, .event-countdown .row.collapse > li {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row, .newslist .row.collapse > .latestnews-item > .row, .event-countdown .row.collapse > li > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns, .newslist .latestnews-item, .event-countdown li {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 36em) {
  .column, .columns, .newslist .latestnews-item, .event-countdown li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns, .newslist .row.row.latestnews-item, .event-countdown li.row.row {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns, .row .newslist .row.row.latestnews-item, .newslist .row .row.row.latestnews-item, .row .event-countdown li.row.row, .event-countdown .row li.row.row {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12, .newslist .latestnews-item, .event-countdown li {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns, .newslist .small-up-1 > .latestnews-item, .event-countdown .small-up-1 > li {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns, .newslist .small-up-2 > .latestnews-item, .event-countdown .small-up-2 > li {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns, .newslist .small-up-3 > .latestnews-item, .event-countdown .small-up-3 > li {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns, .newslist .small-up-4 > .latestnews-item, .event-countdown .small-up-4 > li {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns, .newslist .small-up-5 > .latestnews-item, .event-countdown .small-up-5 > li {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns, .newslist .small-up-6 > .latestnews-item, .event-countdown .small-up-6 > li {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns, .newslist .small-up-7 > .latestnews-item, .event-countdown .small-up-7 > li {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns, .newslist .small-up-8 > .latestnews-item, .event-countdown .small-up-8 > li {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns, .newslist .small-collapse > .latestnews-item, .event-countdown .small-collapse > li {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns, .newslist .small-uncollapse > .latestnews-item, .event-countdown .small-uncollapse > li {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns, .newslist .smedium-up-1 > .latestnews-item, .event-countdown .smedium-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns, .newslist .smedium-up-2 > .latestnews-item, .event-countdown .smedium-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns, .newslist .smedium-up-3 > .latestnews-item, .event-countdown .smedium-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns, .newslist .smedium-up-4 > .latestnews-item, .event-countdown .smedium-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns, .newslist .smedium-up-5 > .latestnews-item, .event-countdown .smedium-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns, .newslist .smedium-up-6 > .latestnews-item, .event-countdown .smedium-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns, .newslist .smedium-up-7 > .latestnews-item, .event-countdown .smedium-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns, .newslist .smedium-up-8 > .latestnews-item, .event-countdown .smedium-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item, .event-countdown .row.smedium-unstack > li {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item, .event-countdown .row.smedium-unstack > li {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns, .newslist .smedium-collapse > .latestnews-item, .event-countdown .smedium-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns, .newslist .smedium-uncollapse > .latestnews-item, .event-countdown .smedium-uncollapse > li {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 36em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns, .newslist .medium-up-1 > .latestnews-item, .event-countdown .medium-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns, .newslist .medium-up-2 > .latestnews-item, .event-countdown .medium-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns, .newslist .medium-up-3 > .latestnews-item, .event-countdown .medium-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns, .newslist .medium-up-4 > .latestnews-item, .event-countdown .medium-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns, .newslist .medium-up-5 > .latestnews-item, .event-countdown .medium-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns, .newslist .medium-up-6 > .latestnews-item, .event-countdown .medium-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns, .newslist .medium-up-7 > .latestnews-item, .event-countdown .medium-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns, .newslist .medium-up-8 > .latestnews-item, .event-countdown .medium-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 36em) and (min-width: 36em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item, .event-countdown .row.medium-unstack > li {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 36em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item, .event-countdown .row.medium-unstack > li {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns, .newslist .medium-collapse > .latestnews-item, .event-countdown .medium-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, .newslist .medium-uncollapse > .latestnews-item, .event-countdown .medium-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns, .newslist .mlarge-up-1 > .latestnews-item, .event-countdown .mlarge-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns, .newslist .mlarge-up-2 > .latestnews-item, .event-countdown .mlarge-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns, .newslist .mlarge-up-3 > .latestnews-item, .event-countdown .mlarge-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns, .newslist .mlarge-up-4 > .latestnews-item, .event-countdown .mlarge-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns, .newslist .mlarge-up-5 > .latestnews-item, .event-countdown .mlarge-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns, .newslist .mlarge-up-6 > .latestnews-item, .event-countdown .mlarge-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns, .newslist .mlarge-up-7 > .latestnews-item, .event-countdown .mlarge-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns, .newslist .mlarge-up-8 > .latestnews-item, .event-countdown .mlarge-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item, .event-countdown .row.mlarge-unstack > li {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item, .event-countdown .row.mlarge-unstack > li {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns, .newslist .mlarge-collapse > .latestnews-item, .event-countdown .mlarge-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns, .newslist .mlarge-uncollapse > .latestnews-item, .event-countdown .mlarge-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns, .newslist .large-up-1 > .latestnews-item, .event-countdown .large-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns, .newslist .large-up-2 > .latestnews-item, .event-countdown .large-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns, .newslist .large-up-3 > .latestnews-item, .event-countdown .large-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns, .newslist .large-up-4 > .latestnews-item, .event-countdown .large-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns, .newslist .large-up-5 > .latestnews-item, .event-countdown .large-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns, .newslist .large-up-6 > .latestnews-item, .event-countdown .large-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns, .newslist .large-up-7 > .latestnews-item, .event-countdown .large-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns, .newslist .large-up-8 > .latestnews-item, .event-countdown .large-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item, .event-countdown .row.large-unstack > li {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item, .event-countdown .row.large-unstack > li {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns, .newslist .large-collapse > .latestnews-item, .event-countdown .large-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns, .newslist .large-uncollapse > .latestnews-item, .event-countdown .large-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 64em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns, .newslist .xlarge-up-1 > .latestnews-item, .event-countdown .xlarge-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns, .newslist .xlarge-up-2 > .latestnews-item, .event-countdown .xlarge-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns, .newslist .xlarge-up-3 > .latestnews-item, .event-countdown .xlarge-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns, .newslist .xlarge-up-4 > .latestnews-item, .event-countdown .xlarge-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns, .newslist .xlarge-up-5 > .latestnews-item, .event-countdown .xlarge-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns, .newslist .xlarge-up-6 > .latestnews-item, .event-countdown .xlarge-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns, .newslist .xlarge-up-7 > .latestnews-item, .event-countdown .xlarge-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns, .newslist .xlarge-up-8 > .latestnews-item, .event-countdown .xlarge-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item, .event-countdown .row.xlarge-unstack > li {
  flex: 0 0 100%;
}
@media screen and (min-width: 64em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item, .event-countdown .row.xlarge-unstack > li {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns, .newslist .xlarge-collapse > .latestnews-item, .event-countdown .xlarge-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, .newslist .xlarge-uncollapse > .latestnews-item, .event-countdown .xlarge-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item, .event-countdown .xxlarge-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item, .event-countdown .xxlarge-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item, .event-countdown .xxlarge-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item, .event-countdown .xxlarge-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item, .event-countdown .xxlarge-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item, .event-countdown .xxlarge-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item, .event-countdown .xxlarge-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item, .event-countdown .xxlarge-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item, .event-countdown .row.xxlarge-unstack > li {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item, .event-countdown .row.xxlarge-unstack > li {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item, .event-countdown .xxlarge-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item, .event-countdown .xxlarge-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 90em) {
  .xxxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-1 > .column, .xxxlarge-up-1 > .columns, .newslist .xxxlarge-up-1 > .latestnews-item, .event-countdown .xxxlarge-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-2 > .column, .xxxlarge-up-2 > .columns, .newslist .xxxlarge-up-2 > .latestnews-item, .event-countdown .xxxlarge-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-3 > .column, .xxxlarge-up-3 > .columns, .newslist .xxxlarge-up-3 > .latestnews-item, .event-countdown .xxxlarge-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-4 > .column, .xxxlarge-up-4 > .columns, .newslist .xxxlarge-up-4 > .latestnews-item, .event-countdown .xxxlarge-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-5 > .column, .xxxlarge-up-5 > .columns, .newslist .xxxlarge-up-5 > .latestnews-item, .event-countdown .xxxlarge-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-6 > .column, .xxxlarge-up-6 > .columns, .newslist .xxxlarge-up-6 > .latestnews-item, .event-countdown .xxxlarge-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-7 > .column, .xxxlarge-up-7 > .columns, .newslist .xxxlarge-up-7 > .latestnews-item, .event-countdown .xxxlarge-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-8 > .column, .xxxlarge-up-8 > .columns, .newslist .xxxlarge-up-8 > .latestnews-item, .event-countdown .xxxlarge-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 90em) and (min-width: 90em) {
  .xxxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxxlarge-unstack > .column, .row.xxxlarge-unstack > .columns, .newslist .row.xxxlarge-unstack > .latestnews-item, .event-countdown .row.xxxlarge-unstack > li {
  flex: 0 0 100%;
}
@media screen and (min-width: 90em) {
  .row.xxxlarge-unstack > .column, .row.xxxlarge-unstack > .columns, .newslist .row.xxxlarge-unstack > .latestnews-item, .event-countdown .row.xxxlarge-unstack > li {
    flex: 1 1 0px;
  }
  .xxxlarge-collapse > .column, .xxxlarge-collapse > .columns, .newslist .xxxlarge-collapse > .latestnews-item, .event-countdown .xxxlarge-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .xxxlarge-uncollapse > .column, .xxxlarge-uncollapse > .columns, .newslist .xxxlarge-uncollapse > .latestnews-item, .event-countdown .xxxlarge-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 112.5em) {
  .xxxxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxxxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxxxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxxxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxxxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxxxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxxxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxxxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxxxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxxxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxxxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxxxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxxxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxxxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxxxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxxxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxxxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxxxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxxxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxxxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-1 > .column, .xxxxlarge-up-1 > .columns, .newslist .xxxxlarge-up-1 > .latestnews-item, .event-countdown .xxxxlarge-up-1 > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-2 > .column, .xxxxlarge-up-2 > .columns, .newslist .xxxxlarge-up-2 > .latestnews-item, .event-countdown .xxxxlarge-up-2 > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-3 > .column, .xxxxlarge-up-3 > .columns, .newslist .xxxxlarge-up-3 > .latestnews-item, .event-countdown .xxxxlarge-up-3 > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-4 > .column, .xxxxlarge-up-4 > .columns, .newslist .xxxxlarge-up-4 > .latestnews-item, .event-countdown .xxxxlarge-up-4 > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-5 > .column, .xxxxlarge-up-5 > .columns, .newslist .xxxxlarge-up-5 > .latestnews-item, .event-countdown .xxxxlarge-up-5 > li {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxxxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-6 > .column, .xxxxlarge-up-6 > .columns, .newslist .xxxxlarge-up-6 > .latestnews-item, .event-countdown .xxxxlarge-up-6 > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-7 > .column, .xxxxlarge-up-7 > .columns, .newslist .xxxxlarge-up-7 > .latestnews-item, .event-countdown .xxxxlarge-up-7 > li {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxxxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-8 > .column, .xxxxlarge-up-8 > .columns, .newslist .xxxxlarge-up-8 > .latestnews-item, .event-countdown .xxxxlarge-up-8 > li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 112.5em) and (min-width: 112.5em) {
  .xxxxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxxxlarge-unstack > .column, .row.xxxxlarge-unstack > .columns, .newslist .row.xxxxlarge-unstack > .latestnews-item, .event-countdown .row.xxxxlarge-unstack > li {
  flex: 0 0 100%;
}
@media screen and (min-width: 112.5em) {
  .row.xxxxlarge-unstack > .column, .row.xxxxlarge-unstack > .columns, .newslist .row.xxxxlarge-unstack > .latestnews-item, .event-countdown .row.xxxxlarge-unstack > li {
    flex: 1 1 0px;
  }
  .xxxxlarge-collapse > .column, .xxxxlarge-collapse > .columns, .newslist .xxxxlarge-collapse > .latestnews-item, .event-countdown .xxxxlarge-collapse > li {
    padding-right: 0;
    padding-left: 0;
  }
  .xxxxlarge-uncollapse > .column, .xxxxlarge-uncollapse > .columns, .newslist .xxxxlarge-uncollapse > .latestnews-item, .event-countdown .xxxxlarge-uncollapse > li {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
/* Aninmations
================================================== */
/* Hover bob effect */
.hvr-bob, .newslist .latestnews-item > a {
  transition-duration: 0.5s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
}
.hvr-bob:hover, .newslist .latestnews-item > a:hover {
  transform: translateY(-6px) !important;
  animation-name: hover;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.ripple {
  position: absolute;
  z-index: 10;
  background: #dcdbdb;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: ripple 1s linear infinite;
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
/* Main
================================================== */
main {
  padding: 4rem 0;
}
@media (min-width: 576px) {
  main {
    padding: 6rem 0;
  }
}

.label-warning {
  display: none;
}

.wf-column {
  position: relative;
}

.fields-container {
  margin: 0;
}
.fields-container ul {
  margin: 0;
  list-style: none;
}

.box-shadow-style-1, .newslist .latestnews-item > a, .map-card, .image-overlay, .colour-wrapper div, .animation-wrapper div {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}
.box-shadow-style-2, .showcase-slider .n2-ss-slide-active {
  box-shadow: 12px 12px 24px 0px rgba(108, 54, 14, 0.5);
}

.image-overlay {
  position: absolute;
}
.image-overlay--1 {
  top: 10rem;
}

.main--full-width {
  position: relative;
  padding: 0;
}
.main--full-width .wf-columns {
  gap: 0;
}
.main--colour-1 *, .main--colour-4 * {
  color: #ffffff;
}
.main--colour-3 p,
.main--colour-3 li {
  font-weight: 500;
}
.main--colour-1 {
  background-color: #009bb7;
}
.main--colour-2 {
  background-color: #ccb46c;
}
.main--colour-3 {
  background-color: #006ba6;
}

/* Social
================================================== */
.social ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.social li {
  width: auto;
  margin: 0;
  padding: 0;
}
.social svg {
  width: 1.5rem;
  height: 1.5rem;
}
.social svg * {
  fill: #0a0a0a;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}
.social p {
  display: flex;
  margin: 0;
}
.social-fixed .social ul {
  position: fixed;
  top: 25%;
  right: 0;
  z-index: 100;
  display: none;
  margin: 0;
}
@media (min-width: 1024px) {
  .social-fixed .social ul {
    display: block;
  }
}
.social-fixed .social h3 {
  display: none;
}
.social-fixed .social li,
.social-fixed .social a {
  width: 48px;
  height: 48px;
}
.social-fixed .social li {
  margin: 1rem;
  padding: 0;
  text-align: center;
}
.social-fixed .social span {
  display: none;
}
.social-fixed .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 14px;
  line-height: 100%;
  border-radius: 50%;
  background-color: #fbfbfb;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}
.social-fixed .social a:hover {
  background-color: #ccb46c;
}
.social-fixed .social a:hover * {
  fill: #0a0a0a;
}

/* Countdown
================================================== */
.event-countdown ul {
  display: flex;
  flex-flow: column;
  align-items: normal;
  justify-content: normal;
  position: relative;
  z-index: 1;
  margin: 1rem auto;
  padding: 0;
  text-align: center;
  font-size: 1.2rem;
}
@media (min-width: 480px) {
  .event-countdown ul {
    flex-flow: row wrap;
  }
}
.event-countdown p {
  display: none;
}
.event-countdown li {
  display: inline-flex;
  justify-content: center;
  flex: 1;
  margin: 0;
  padding: 1rem;
  list-style: none;
  text-align: center;
  color: #009bb7;
}
@media (min-width: 480px) {
  .event-countdown li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  .event-countdown li {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.event-countdown li div {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.event-countdown li span {
  line-height: 100%;
}
.event-countdown__number {
  font-size: 2.6rem;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .event-countdown__number {
    font-size: 3rem;
  }
}
.event-countdown__text {
  margin-top: 1rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .event-countdown__text {
    font-size: 1.2rem;
  }
}

/* Staats
================================================== */
.stat {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.stat__number {
  display: flex;
  font-size: 2.6rem;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .stat__number {
    font-size: 3rem;
  }
}
.stat__text {
  margin-top: 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}
@media (min-width: 1024px) {
  .stat__text {
    font-size: 1.2rem;
  }
}

/* Map
================================================== */
.map-card {
  max-width: 600px;
  margin: auto;
}
.map-card * {
  color: #009bb7 !important;
}
.map-card h6 {
  display: none;
}
.map-card p {
  margin: 0;
}
.map-card .event-location {
  padding: 1.5rem;
  background-color: #ffffff;
}
.map-card .event-location ul {
  padding: 0;
}
.map-card .event-location li {
  display: inline-flex;
  margin: 0;
  padding: 0 0.5rem 0 0;
}
.map-card .event-location__name {
  width: 100%;
  margin-bottom: 0.5rem !important;
  font-size: 1.2rem;
}
.map-card .event-location__name strong {
  font-weight: 600;
}

/* Testimonials
================================================== */
.testimonials-slider__image {
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 50%;
  border: 4px solid #006ba6;
}
.testimonials-slider__quote .n2-ss-item-image-content {
  display: flex;
  position: relative;
  top: -1rem;
}
@media (min-width: 960px) {
  .testimonials-slider__quote .n2-ss-item-image-content {
    top: 0;
    left: -4rem;
  }
}

/* Gallery Slider
================================================== */
@media (max-width: 767px) {
  .showcase-slider .n2-ss-slider-1 {
    min-height: 400px !important;
  }
}
@media (max-width: 575px) {
  .showcase-slider .n2-ss-slider-1 {
    min-height: 360px !important;
  }
}
.showcase-slider .n2-ss-static-slide {
  z-index: -1 !important;
}

/* Speaker Slider
================================================== */
.speaker-slider__image {
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 50%;
  border: 4px solid #006ba6;
}
.speaker-slider__title {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
}
.speaker-slider__details {
  font-size: 0.8rem;
}
.speaker-slider__details p {
  margin: 0 0 0.25rem 0;
}

/* Newsletter Form
================================================== */
.newsletter-form .ba-form-field-item,
.newsletter-form .ba-form-column,
.newsletter-form .ba-form-submit-btn {
  margin: 0 !important;
}
.newsletter-form .ba-form-column-wrapper .ba-form-column {
  align-self: center;
}
.newsletter-form [class*=span] {
  width: auto !important;
}
.newsletter-form input[type=email],
.newsletter-form .ba-form-submit-btn {
  min-height: 60px;
}

/* Cards
================================================== */
.cards {
  gap: 3rem;
  margin-bottom: 3rem;
}
.cards--narrow {
  max-width: 1024px;
  margin: auto;
}
.cards .card {
  position: relative;
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: #ccb46c;
}
.cards .card * {
  color: #ffffff;
}
.cards figure {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 320px !important;
  margin-left: -2rem !important;
  margin-top: -2rem !important;
  overflow: hidden;
  padding-bottom: 1rem;
  width: calc(100% + 4rem);
}
.cards figure img {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  -o-object-position: center !important;
  object-position: center !important;
}

/* Header
================================================== */
header {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  transition: 500ms ease-in-out;
}
@media (min-width: 576px) {
  header {
    position: fixed;
  }
}
header .row {
  max-width: 100%;
  align-items: center;
}
@media (min-width: 576px) {
  header .row {
    padding: 0 1rem;
  }
}
@media (min-width: 1024px) {
  header .row {
    padding: 0;
  }
}
@media (min-width: 1280px) {
  header .row {
    padding: 0 1rem;
  }
}
.shrink header {
  background: #009bb7;
}
@media (max-width: 575px) {
  .shrink header {
    position: fixed;
  }
}
@media (min-width: 576px) {
  .shrink header .header__logo img {
    width: 160px;
  }
}
@media (min-width: 576px) {
  .shrink header .header__wrap {
    padding: 0.5rem 0;
  }
}

.header__wrap {
  padding: 1.5rem 0;
  transition: 500ms ease-in-out;
}
.header__logo {
  flex: none;
  position: relative;
  z-index: 20;
}
@media (min-width: 1280px) {
  .header__logo {
    margin-right: 4rem;
  }
}
.header__logo a {
  display: flex;
}
.header__logo img {
  width: 240px;
  transition: 500ms ease-in-out;
}
@media (min-width: 1024px) {
  .header__logo img {
    width: 200px;
  }
}
@media (min-width: 1280px) {
  .header__logo img {
    width: 240px;
  }
}

/* Navigation
================================================== */
.nav {
  width: auto;
  margin: 0;
  padding: 0;
}
.nav ul {
  display: none;
  padding: 0;
}
.nav li {
  display: flex;
  position: relative;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
@media (max-width: 767px) {
  .nav li {
    width: auto;
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .nav li:last-of-type .mod-menu__sub {
    right: 0;
  }
}
@media (min-width: 1024px) {
  .nav li:last-of-type .mod-menu__sub li {
    text-align: right;
  }
}
.nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: #ffffff;
  font-size: 1rem;
  text-decoration: none;
  line-height: 1.2;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .nav a {
    font-size: 0.8rem;
  }
}
@media (min-width: 1440px) {
  .nav a {
    font-size: 0.9rem;
  }
}
.nav a.button {
  margin: 1rem 0 0 0;
  padding: 1rem 1.5rem;
  font-size: 0.9rem;
  color: #ffffff !important;
}
@media (min-width: 1024px) {
  .nav a.button {
    margin: 0;
    font-size: 0.8rem;
  }
}
@media (min-width: 1280px) {
  .nav a.button {
    font-size: 0.9rem;
  }
}
.nav a:hover,
.nav .current > a,
.nav .active > a {
  color: #ccb46c;
}
.nav a:hover:after,
.nav .current > a:after,
.nav .active > a:after {
  border-color: #ccb46c transparent transparent transparent !important;
}
@media (max-width: 1023px) {
  .nav .parent {
    flex-direction: column;
  }
}
.nav .parent > a {
  position: relative;
}
.nav .parent > a:after {
  content: "";
  display: inline-flex;
  width: 0;
  height: 0;
  margin-left: 0.5rem;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #ffffff transparent transparent transparent;
  transition: 500ms ease-in-out;
}
.nav .parent ul {
  display: none;
  flex-flow: column nowrap;
  justify-self: left;
  list-style: none;
  width: 100%;
  min-width: 180px;
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .nav .parent ul {
    position: absolute;
    top: 100%;
    align-items: flex-start;
  }
}
.nav .parent ul li {
  width: 100%;
}
@media (max-width: 1023px) {
  .nav .parent ul li {
    justify-content: center;
  }
}
.nav .parent ul a {
  width: 100%;
  font-size: 0.8rem;
}
@media (min-width: 1024px) {
  .nav .parent ul a {
    justify-content: left;
    text-align: left;
  }
}

/* Main Menu
================================================== */
@media (min-width: 1280px) {
  .nav--main {
    margin-left: auto;
  }
}
.nav--main .nav {
  display: none;
}
@media (min-width: 1280px) {
  .nav--main .nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
@media (min-width: 1440px) {
  .nav--main .nav {
    max-width: 90%;
    margin-left: auto;
  }
}
.nav--main .nav .parent:hover ul {
  display: flex;
}
.nav--main .mod-menu__sub {
  background-color: #009bb7;
}
@media (min-width: 576px) {
  .shrink .nav--main a {
    font-size: 0.8rem;
  }
}
.nav-active .nav--main .nav {
  background-color: #009bb7;
}

/* Nav Overlay
================================================== */
body.nav-active {
  overflow: hidden;
  scrollbar-gutter: stable;
}

.nav--overlay {
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #009bb7;
}
.nav-active .nav--overlay {
  display: flex;
}
.nav--overlay .nav {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 12rem;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding-left: calc(100vw - 100%);
}
.nav--overlay .nav li {
  justify-content: center;
  flex-wrap: wrap;
}
.nav--overlay .nav a {
  font-size: 1rem !important;
}
.nav--overlay .nav a.button {
  margin: 1rem 0 0 0 !important;
  font-size: 0.9rem !important;
}
.nav--overlay .nav ul.active {
  display: flex;
}
.nav--overlay .nav .parent ul {
  position: static !important;
}
@media (min-width: 768px) {
  .nav--overlay .nav .parent:hover ul {
    display: flex;
  }
}
.nav--overlay .nav .mod-menu__sub a {
  font-size: 0.9rem !important;
}
.nav--overlay .nav-button-wrap {
  margin-left: auto;
  margin-right: -0.625rem;
}
@media (min-width: 576px) {
  .nav--overlay .nav-button-wrap {
    margin-right: 0.9375rem;
  }
}
.nav--overlay .nav-button {
  margin: 1.5rem 0 1.5rem auto;
}
@media (min-width: 576px) {
  .nav--overlay .nav-button {
    margin-right: 1rem;
  }
}
@media (min-width: 1024px) {
  .nav--overlay .nav-button {
    margin-right: 0rem;
  }
}
@media (min-width: 1280px) {
  .nav--overlay .nav-button {
    margin-right: 1rem;
  }
}
.shrink .nav--overlay .nav-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Hamburger / Menu
================================================== */
.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 1rem;
  background: transparent;
  outline: 0;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .nav-button {
    padding-right: 0;
  }
}
.nav-button p {
  margin: 0;
  padding: 0 0.75rem 0 0;
  color: #ffffff;
}
.nav-button span,
.nav-button span:before,
.nav-button span:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2em;
  height: 4px;
  background: #ffffff;
  border-radius: 1px;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}
.nav-button span {
  position: relative;
}
.nav-button span:before {
  top: -10px;
}
.nav-button span:after {
  top: auto;
  bottom: -10px;
}
.nav-active .nav-button span {
  background: transparent;
}
.nav-active .nav-button span:before, .nav-active .nav-button span:after {
  top: 0;
  background: #ffffff;
}
.nav-active .nav-button span:before {
  transform: rotate(45deg);
}
.nav-active .nav-button span:after {
  transform: rotate(-45deg);
}
@media (min-width: 1280px) {
  .nav-button {
    display: none;
  }
}

button.nav-button {
  background: transparent;
}

/* Banner / Slider
================================================== */
#banner {
  padding: 0;
}
#banner .n2-ss-slide-limiter {
  max-width: 100% !important;
}
#banner .slider-button a {
  transition: 500ms ease-in-out !important;
}
@media (max-width: 767px) {
  #banner h1 {
    font-size: 2rem !important;
  }
}

.banner--main .n2-ss-slide-background-image {
  mix-blend-mode: exclusion;
  opacity: 0.8;
}
.banner--main .n2-ss-slide-background-color {
  z-index: 7 !important;
  mix-blend-mode: color;
  opacity: 0.5;
  background-color: #ccb46c !important;
}
.banner--main .n2-ss-slide-background {
  background-color: #32afc7;
}
.banner--main .n2-ss-button-container a {
  padding: 1rem 1.5rem !important;
  border-radius: 0 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.banner--main .button--has-icon-right .n2-ss-button-container a {
  padding-right: 1rem !important;
}
.banner--main .button--has-icon-left .n2-ss-button-container a {
  padding-left: 1rem !important;
}
.banner__overlay, .banner__video-bg {
  height: 100% !important;
}
.banner__overlay {
  mix-blend-mode: color;
}
.banner__video-bg {
  opacity: 0.8;
}
.banner__video-bg .n2_ss_video_player {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.banner__video-bg .n2_ss_video_player iframe {
  box-sizing: border-box;
  height: 65vw !important;
  left: 50% !important;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50% !important;
  width: 180vh !important;
}

/* Page Specific Styling
================================================== */
/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
  padding: 4rem 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
@media (min-width: 576px) {
  section {
    padding: 6rem 0;
  }
}
section .row {
  position: relative;
  z-index: 20;
}

.section--narrow {
  padding: 3rem 0;
}
.section--white-content * {
  color: #ffffff;
}
.section--white-content .primary-colour {
  color: #009bb7;
}
.section--white-content .secondary-colour {
  color: #ccb46c;
}
.section--white-content .tertiary-colour {
  color: #006ba6;
}
.section--colour-bg-1 {
  background-color: #009bb7;
}
.section--colour-bg-2 {
  background-color: #ccb46c;
}
.section--colour-bg-3 {
  background-color: #006ba6;
}
.section--pattern-1:after {
  content: "";
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40%;
  background: url("../images/dot-pattern-bg.svg") repeat 50% 50%;
  background-size: 25%;
}
.section--location:before, .section--location:after {
  content: "";
  position: absolute;
}
.section--location:before {
  z-index: 15;
  background-color: #006ba6;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60%;
  opacity: 0.75;
}
@media (max-width: 640px) {
  .section--location:before {
    right: 0;
    bottom: auto;
    width: 100%;
    height: 60%;
  }
}
.section--location:after {
  z-index: 10;
  inset: 0;
  background-color: #009bb7;
  mix-blend-mode: exclusion;
}
.section--overlay section:before, .section--overlay-secondary section:before, .section--overlay-tertiary section:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #009bb7;
  opacity: 0.8;
}
.section--overlay-secondary {
  background-color: #ccb46c;
}
.section--overlay-tertiary {
  background-color: #006ba6;
}

/* News
================================================== */
.newslist .latestnews-items {
  flex-flow: column !important;
  align-items: normal !important;
  -webkit-justify-content: normal !important;
  -ms-flex-pack: normal !important;
  justify-content: normal !important;
  overflow: visible !important;
  padding: 0;
}
@media (min-width: 576px) {
  .newslist .latestnews-items {
    flex-flow: row wrap !important;
  }
}
.newslist .latestnews-item {
  flex: 1;
  padding: 0 1rem 2rem !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 2rem 2rem !important;
  }
}
@media (min-width: 960px) {
  .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.newslist .latestnews-item > a {
  display: block;
  height: 100%;
}
.newslist .latestnews-item > a:hover .detail_category,
.newslist .latestnews-item > a:hover .detail_tags,
.newslist .latestnews-item > a:hover .detail_featured {
  opacity: 1;
}
.newslist .latestnews-item > a:hover .link a {
  background: #ba9c41;
}
.newslist .latestnews-item > a:hover .link a span {
  color: #ffffff;
}
.newsSection .newslist .latestnews-item {
  border: 0;
}
.newslist .latestnews-item.active {
  opacity: 1 !important;
}
.newslist .latestnews-item.featured:hover .link a {
  background: #004469 !important;
}
.newslist .latestnews-item.featured .newsinfo {
  background-color: #c1a54e;
}
.newslist .latestnews-item.featured .newsinfo * {
  color: #ffffff;
}
.newslist .latestnews-item.featured .newsinfo .link a {
  background-color: #006ba6;
}
.newslist .newshead {
  float: none !important;
  margin: 0 !important;
}
.newslist .picture {
  max-width: 100% !important;
  max-height: 360px !important;
}
.newslist .innerpicture {
  width: 100%;
}
.newslist .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.newslist .innerpicture picture {
  display: block;
  width: 100%;
}
.newslist .innerpicture img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newslist .news {
  position: relative !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0rem !important;
  background-color: transparent !important;
  border: none !important;
}
.newslist .innernews {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.newslist .newstitle {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  margin: 0 0 0.5em 0 !important;
}
.newslist .newstitle a {
  color: #009bb7;
}
.newslist .newstitle a:hover {
  color: #ccb46c;
}
.newslist .newsinfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 2rem 4rem !important;
  background-color: #fbfbfb;
}
.newslist .newsinfo * {
  color: #009bb7;
}
.newslist .newsintro {
  line-height: 1.5 !important;
  font-size: 0.9rem;
}
.newslist .detail_category,
.newslist .detail_tags,
.newslist .detail_featured {
  opacity: 1;
  display: inline-flex;
  padding: 0.4rem 0.8rem;
  border-radius: 50px;
  background: #fbfbfb;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #009bb7;
  transition: 500ms ease-in-out;
}
@media (min-width: 576px) {
  .newslist .detail_category,
  .newslist .detail_tags,
  .newslist .detail_featured {
    opacity: 0.25;
  }
}
.newslist .detail_featured {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: #ccb46c;
  color: #ffffff;
}
.newslist .detail_date {
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem 0;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}
.newslist .detail_date .detail_data {
  color: #ccb46c !important;
}
.newslist .delimiter {
  display: none;
}
.newslist .link {
  width: 100% !important;
  margin-top: 1.5em !important;
}
.newslist .link a {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #ccb46c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}
.newslist .link span {
  color: #ffffff;
  transition: 500ms ease-in-out;
}
@media (min-width: 576px) {
  .newslist--full-width .latestnews-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.newslist--full-details .latestnews-item .newsinfo {
  padding: 1.5rem 1rem !important;
}
.newslist--full-details .latestnews-item ul,
.newslist--full-details .latestnews-item p {
  margin-bottom: 1rem !important;
}
.newslist--contain .picture {
  margin: auto;
}
.newslist--contain .innerpicture img {
  object-fit: contain;
  padding: 5%;
}
.newslist--size-down .picture {
  margin: auto;
}
.newslist--size-down .innerpicture img {
  object-fit: scale-down;
  padding: 5% 10% 0 !important;
}
.newslist--small-card .newshead {
  margin-top: 1.5rem !important;
}
.newslist--small-card .newsinfo {
  padding: 1.5rem !important;
}
.newslist--center .newstitle,
.newslist--center .newsintro {
  text-align: center !important;
}
@media (min-width: 576px) {
  .newslist--three .latestnews-item:first-of-type, .newslist--3-1 .latestnews-item:first-of-type {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 960px) {
  .newslist--three .latestnews-item:first-of-type, .newslist--3-1 .latestnews-item:first-of-type {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
@media (max-width: 575px) {
  .newslist--four ul.latestnews-items, .newslist--4-2-1 ul.latestnews-items {
    flex-direction: column !important;
  }
}
.newslist--four .latestnews-item, .newslist--4-2-1 .latestnews-item {
  padding: 0 1rem 2rem !important;
}
@media (max-width: 575px) {
  .newslist--four .latestnews-item, .newslist--4-2-1 .latestnews-item {
    flex: 1 1 0px !important;
    min-width: 0 !important;
    max-width: 100% !important;
  }
}
@media (min-width: 576px) {
  .newslist--four .latestnews-item, .newslist--4-2-1 .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 1024px) {
  .newslist--four .latestnews-item, .newslist--4-2-1 .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0rem !important;
  }
}
@media (max-width: 575px) {
  .newslist--4-3-2-1 ul.latestnews-items {
    flex-direction: column !important;
  }
}
.newslist--4-3-2-1 .latestnews-item {
  padding: 0 1rem 2rem !important;
}
@media (max-width: 575px) {
  .newslist--4-3-2-1 .latestnews-item {
    flex: 1 1 0px !important;
    min-width: 0 !important;
    max-width: 100% !important;
  }
}
@media (min-width: 576px) {
  .newslist--4-3-2-1 .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 960px) {
  .newslist--4-3-2-1 .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
@media (min-width: 1280px) {
  .newslist--4-3-2-1 .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0rem !important;
  }
}
.newslist--logo-circle .innerpicture {
  width: 180px;
  height: 180px;
  position: relative;
  margin: auto auto 1rem auto;
  border: 4px solid #006ba6;
  border-radius: 50%;
  background-color: #ffffff;
}
.newslist--logo-circle .innerpicture img {
  object-fit: contain;
  padding: 2rem;
}
.newslist--testimonials .picture svg {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
}
.newslist--testimonials .newsintro {
  margin-bottom: 1rem;
  font-weight: 500;
  font-style: italic;
}
.newslist--testimonials .detail_data {
  text-transform: uppercase;
}
.newslist--testimonials .detail_featured {
  opacity: 1;
}

/* News Page
  ================================================== */
.news-item-article {
  background-color: #d3d3d3;
}
.news-item-article .main-content {
  flex-direction: column;
  padding: 0;
}
.news-item-article .published {
  margin: 0;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.news-item-article .com-content-image,
.news-item-article .item-page {
  max-width: 960px;
  width: 100%;
  margin: auto;
}
.news-item-article .com-content-image {
  margin-bottom: 0;
}
.news-item-article .com-content-image figure {
  position: relative;
  margin: 0;
  background-color: #ffffff;
}
.news-item-article .com-content-image img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
}
.news-item-article .item-page {
  padding: 3em;
  background-color: #fbfbfb;
}
.news-item-article .item-page * {
  color: #0a0a0a;
}
.news-item-article .article-info.muted {
  display: block;
  color: #009bb7;
  margin-bottom: 2em;
}
.news-item-article .button--back {
  color: #ffffff;
}
.news-item-article--sponsor .com-content-image figure {
  padding: 5%;
}

.news-item-article .com-content-image,
.news-item-article .item-page {
  margin: auto;
  max-width: 960px;
  width: 100%;
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-info.muted {
  display: none;
}

/* Events
================================================== */
.select-event-wrap {
  position: relative;
  z-index: 100;
  display: flex;
}
@media (max-width: 959px) {
  .select-event-wrap {
    align-items: center;
    position: static;
  }
}
.select-event-wrap .button--select-event {
  display: none;
}
@media (min-width: 1024px) {
  .select-event-wrap .button--select-event {
    display: inline-flex;
  }
}

.select-menu-active .social-fixed {
  opacity: 0;
  right: -100px;
}

.event-list__wrap {
  display: none;
  position: absolute;
  top: -0.5rem;
  right: 0;
  z-index: 100;
  padding: 0.5rem;
  background-color: #ffffff;
}
@media (min-width: 960px) {
  .event-list__wrap {
    top: 100%;
    right: 0;
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.select-menu-active .event-list__wrap {
  display: block;
}
.event-list__wrap .subheading {
  position: absolute;
  margin: 0;
  color: #009bb7;
  top: 2.2rem;
  left: 1.5rem;
}

@media (max-width: 575px) {
  .button--select-event, .button--select-event-close {
    padding: 0.75rem 1rem !important;
    font-size: 0.7rem !important;
  }
}
.button--select-event {
  margin: 0 0 0 auto;
  font-size: 0.8rem;
  color: #ffffff !important;
}
@media (min-width: 960px) {
  .button--select-event {
    font-size: 0.5rem;
  }
}
.button--select-event-close {
  margin-top: 1.25rem !important;
}
@media (min-width: 960px) {
  .button--select-event-close {
    display: none !important;
  }
}
.button--select-event-close p {
  color: #0a0a0a;
}
.button--select-event-close span {
  background: transparent;
}
.button--select-event-close span:before, .button--select-event-close span:after {
  top: 0;
  background: #0a0a0a !important;
}
.button--select-event-close span:before {
  transform: rotate(45deg);
}
.button--select-event-close span:after {
  transform: rotate(-45deg);
}

.nav--main .button--select-event {
  margin-top: 2rem !important;
  font-size: 0.7rem !important;
}
@media (min-width: 1024px) {
  .nav--main .button--select-event {
    display: none;
  }
}

.event-menu__links .newslist .latestnews-items {
  flex-direction: column !important;
  width: 100%;
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .event-menu__links .newslist .latestnews-items {
    width: 400px;
  }
}
.event-menu__links .newslist .latestnews-item {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  padding: 0 0 1rem 0 !important;
  transform: translate(0px, 0px) !important;
  opacity: 1 !important;
  visibility: visible !important;
}
.event-menu__links .newslist .latestnews-item > a {
  position: relative;
  overflow: hidden;
  background-color: #0a0a0a !important;
  border: 0 !important;
}
.event-menu__links .newslist .news {
  position: relative;
  z-index: 20;
}
.event-menu__links .newslist .newsinfo {
  padding: 1.5rem !important;
  background-color: transparent;
}
.event-menu__links .newslist .newsinfo a {
  display: block;
}
.event-menu__links .newslist .newsinfo img {
  width: 100%;
}
.event-menu__links .newslist .newsextra {
  text-align: left;
}

/* Footer
================================================== */
footer * {
  color: #ffffff;
}
footer .moduletable {
  margin: 1rem 0;
}
@media (min-width: 576px) {
  footer .moduletable {
    margin: 0;
  }
}
footer .nav .mod-menu__sub {
  display: none !important;
}
footer .nav li {
  margin: 0.5rem 0;
  text-align: left;
}
footer .nav a {
  display: inline-flex;
  justify-content: flex-start;
  padding: 0;
  font-size: 0.9rem;
}
footer .event-location ul {
  padding: 0;
  list-style: none;
}
footer .event-location li {
  margin: 0;
  padding: 0 0 0.25rem 0;
}

.footer__top {
  padding: 5rem 0;
  background-color: #009bb7;
}
.footer__column-1 img,
.footer__column-1 svg {
  max-width: 220px;
}
.footer__logo {
  display: block;
  margin-bottom: 3rem;
}
.footer__social {
  margin-top: 2rem;
}
.footer__social svg * {
  fill: #ffffff;
}
.footer__social a:hover svg * {
  fill: #ccb46c;
}
.footer__bottom {
  padding: 0.5rem 0;
  background-color: #0a0a0a;
}
.footer__bottom .row {
  align-items: center;
}
.footer__bottom .nav {
  padding: 0;
}
.footer__bottom .nav li {
  display: block;
  text-align: center;
}
@media (min-width: 576px) {
  .footer__bottom .nav li {
    display: inline-flex;
    text-align: center;
    margin: 0 2rem 0 0;
  }
}
.footer__bottom .nav a {
  text-transform: uppercase;
  font-size: 0.8rem;
}
.footer__signature {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
}
@media (min-width: 576px) {
  .footer__signature {
    justify-content: flex-end;
  }
}
.footer__signature a {
  display: flex;
  align-items: center;
}
.footer__signature img {
  margin-left: 1rem;
  width: 12rem;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}