/* News
================================================== */
.newslist {
	.latestnews-items {
		flex-flow: column !important;
		align-items: normal !important;
		-webkit-justify-content: normal !important;
		-ms-flex-pack: normal !important;
		justify-content: normal !important;
		overflow: visible !important;
		padding: 0;
		@include bp(medium) {
			flex-flow: row wrap !important;
		}
	}
	.latestnews-item {
		flex: 1;
		@extend .columns;
		@extend .small-12;
		padding: 0 1rem 2rem !important;
		@include bp(medium) {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 2rem 2rem !important;
		}
		@include bp(large) {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}
		& > a {
			display: block;
			height: 100%;
			@extend .hvr-bob !optional;
			@extend .box-shadow-style-1;
			&:hover {
				.detail_category,
				.detail_tags,
				.detail_featured {
					opacity: 1;
				}
				.link {
					a {
						background: darken($secondary-colour, 12%);
						span {
							color: $white;
						}
					}
				}
			}
		}
		.newsSection & {
			border: 0;
		}
		&.active {
			opacity: 1 !important;
		}
		&.featured {
			&:hover {
				.link {
					a {
						background: darken($tertiary-colour, 12%) !important;
					}
				}
			}
			.newsinfo {
				background-color: darken($secondary-colour, 8%);
				* {
					color: $white;
				}
				.link {
					a {
						background-color: $tertiary-colour;
					}
				}
			}
		}
	}
	.newshead {
		float: none !important;
		margin: 0 !important;
	}
	.picture {
		max-width: 100% !important;
		max-height: 360px !important;
	}
	.innerpicture {
		width: 100%;
		&:after {
			content: "";
			display: block;
			padding-bottom: 60%;
		}
		picture {
			display: block;
			width: 100%;
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.news {
		position: relative !important;
		height: 100% !important;
		margin: 0 !important;
		padding: 0rem !important;
		background-color: transparent !important;
		border: none !important;
	}
	.innernews {
		align-items: stretch;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.newstitle {
		font-size: 1.4rem;
		font-family: $primary-font !important;
		font-weight: 700 !important;
		line-height: $base-heading-line-height !important;
		margin: 0 0 0.5em 0 !important;
		a {
			color: $primary-colour;
			&:hover {
				color: $secondary-colour;
			}
		}
	}
	.newsinfo {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 2rem 2rem 4rem !important;
		background-color: $off-white;
		* {
			color: $primary-colour;
		}
	}
	.newsintro {
		line-height: $base-line-height !important;
		font-size: 0.9rem;
	}
	.detail_category,
	.detail_tags,
	.detail_featured {
		opacity: 1;
		display: inline-flex;
		padding: 0.4rem 0.8rem;
		border-radius: 50px;
		background: $off-white;
		font-family: $primary-font;
		font-weight: 700;
		font-size: 0.8rem;
		color: $primary-colour;
		transition: $global-transition;
		@include bp(medium) {
			opacity: 0.25;
		}
	}
	.detail_featured {
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		background-color: $secondary-colour;
		color: $white;
	}
	.detail_date {
		display: flex;
		align-items: center;
		margin: 0 0 0.5rem 0;
		text-transform: uppercase;
		font-family: $primary-font;
		font-size: 0.8rem;
		font-weight: 600;
		.detail_data {
			color: $secondary-colour !important;
		}
	}
	.delimiter {
		display: none;
	}
	.link {
		width: 100% !important;
		margin-top: 1.5em !important;
		a {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0.5rem 0;
			background-color: $secondary-colour;
			text-align: center;
			font-family: $primary-font;
			text-transform: uppercase;
			font-weight: 600;
		}
		span {
			color: $white;
			transition: $global-transition;
		}
	}
	&--full-width {
		.latestnews-item {
			@include bp(medium) {
				flex: 0 0 100% !important;
				max-width: 100% !important;
			}
		}
	}
	&--full-details {
		.latestnews-item {
			.newsinfo {
				padding: 1.5rem 1rem !important;
			}
			ul,
			p {
				margin-bottom: 1rem !important;
			}
		}
	}
	&--contain {
		.picture {
			margin: auto;
		}
		.innerpicture {
			img {
				object-fit: contain;
				padding: 5%;
			}
		}
	}
	&--size-down {
		.picture {
			margin: auto;
		}
		.innerpicture {
			img {
				object-fit: scale-down;
				padding: 5% 10% 0 !important;
			}
		}
	}
	&--small-card {
		.newshead {
			margin-top: 1.5rem !important;
		}
		.newsinfo {
			padding: 1.5rem !important;
		}
	}
	&--center {
		.newstitle,
		.newsintro {
			text-align: center !important;
		}
	}
	&--three,
	&--3-1 {
		.latestnews-item {
			&:first-of-type {
				@include bp(medium) {
					flex: 0 0 100%;
					max-width: 100%;
				}
				@include bp(large) {
					flex: 0 0 33.33333%;
					max-width: 33.33333%;
				}
			}
		}
	}
	&--four,
	&--4-2-1 {
		ul.latestnews-items {
			@include bp_max(medium) {
				flex-direction: column !important;
			}
		}
		.latestnews-item {
			padding: 0 1rem 2rem !important;
			@include bp_max(medium) {
				flex: 1 1 0px !important;
				min-width: 0 !important;
				max-width: 100% !important;
			}
			@include bp(medium) {
				flex: 0 0 50%;
				max-width: 50%;
				margin-bottom: 0rem !important;
			}
			@include bp(xlarge) {
				flex: 0 0 25%;
				max-width: 25%;
				margin-bottom: 0rem !important;
			}
		}
	}
	&--4-3-2-1 {
		ul.latestnews-items {
			@include bp_max(medium) {
				flex-direction: column !important;
			}
		}
		.latestnews-item {
			padding: 0 1rem 2rem !important;
			@include bp_max(medium) {
				flex: 1 1 0px !important;
				min-width: 0 !important;
				max-width: 100% !important;
			}
			@include bp(medium) {
				flex: 0 0 50%;
				max-width: 50%;
				margin-bottom: 0rem !important;
			}
			@include bp(large) {
				flex: 0 0 33.33333%;
				max-width: 33.33333%;
			}
			@include bp(xxlarge) {
				flex: 0 0 25%;
				max-width: 25%;
				margin-bottom: 0rem !important;
			}
		}
	}
	&--logo-circle {
		.innerpicture {
			width: 180px;
			height: 180px;
			position: relative;
			margin: auto auto 1rem auto;
			border: 4px solid $tertiary-colour;
			border-radius: 50%;
			background-color: $white;
			img {
				object-fit: contain;
				padding: 2rem;
			}
		}
	}
	&--testimonials {
		.picture {
			svg {
				position: absolute;
				bottom: 1rem;
				left: 2rem;
			}
		}
		.newsintro {
			margin-bottom: 1rem;
			font-weight: 500;
			font-style: italic;
		}
		.detail_data {
			text-transform: uppercase;
		}
		.detail_featured {
			opacity: 1;
		}
	}
}

/* News Page
  ================================================== */
.news-item-article {
	background-color: $medium-grey;
	.main-content {
		flex-direction: column;
		padding: 0;
	}
	.published {
		margin: 0;
		font-family: $primary-font;
		text-transform: uppercase;
		font-weight: 400;
	}
	.com-content-image,
	.item-page {
		max-width: 960px;
		width: 100%;
		margin: auto;
	}
	.com-content-image {
		margin-bottom: 0;
		figure {
			position: relative;
			margin: 0;
			background-color: $white;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			max-height: 400px;
			object-fit: cover;
		}
	}
	.item-page {
		padding: 3em;
		background-color: $off-white;
		* {
			color: $base-font-color;
		}
	}
	.article-info.muted {
		display: block;
		color: $primary-colour;
		margin-bottom: 2em;
	}
	.button--back {
		color: $white;
	}
	&--sponsor {
		.com-content-image {
			figure {
				padding: 5%;
			}
		}
	}
}
.news-item-article .com-content-image,
.news-item-article .item-page {
	margin: auto;
	max-width: 960px;
	width: 100%;
}

.sharethis-inline-share-buttons {
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.article-info.muted {
	display: none;
}
