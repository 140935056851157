/* Sections 
================================================== */
section {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	@include bp(medium) {
		padding: 6rem 0;
	}
	.row {
		position: relative;
		z-index: 20;
	}
}

.section {
	&--narrow {
		padding: 3rem 0;
	}
	&--white-content {
		* {
			color: $white;
		}
		.primary-colour {
			color: $primary-colour;
		}
		.secondary-colour {
			color: $secondary-colour;
		}
		.tertiary-colour {
			color: $tertiary-colour;
		}
	}
	&--colour-bg-1 {
		background-color: $primary-colour;
	}
	&--colour-bg-2 {
		background-color: $secondary-colour;
	}
	&--colour-bg-3 {
		background-color: $tertiary-colour;
	}
	&--pattern-1 {
		&:after {
			content: "";
			position: absolute;
			z-index: 10;
			right: 0;
			bottom: 0;
			left: 0;
			height: 40%;
			background: url("#{$image-directory}dot-pattern-bg.svg") repeat 50% 50%;
			background-size: 25%;
		}
	}
	&--location {
		&:before,
		&:after {
			content: "";
			position: absolute;
		}
		&:before {
			z-index: 15;
			background-color: $tertiary-colour;
			top: 0;
			left: 0;
			bottom: 0;
			width: 60%;
			opacity: 0.75;
			@media (max-width: 640px) {
				right: 0;
				bottom: auto;
				width: 100%;
				height: 60%;
			}
		}
		&:after {
			z-index: 10;
			inset: 0;
			background-color: $primary-colour;
			mix-blend-mode: exclusion;
		}
	}
	&--overlay,
	&--overlay-secondary,
	&--overlay-tertiary {
		section {
			&:before {
				content: "";
				position: absolute;
				inset: 0;
				background-color: $primary-colour;
				opacity: 0.8;
			}
		}
	}
	&--overlay-secondary {
		background-color: $secondary-colour;
	}
	&--overlay-tertiary {
		background-color: $tertiary-colour;
	}
}
