/* Main
================================================== */
main {
	padding: 4rem 0;
	@include bp(medium) {
		padding: 6rem 0;
	}
}

.label-warning {
	display: none;
}

.wf-column {
	position: relative;
}

.fields-container {
	margin: 0;
	ul {
		margin: 0;
		list-style: none;
	}
}

.box-shadow {
	&-style-1 {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
	}
	&-style-2 {
		box-shadow: 12px 12px 24px 0px rgba(108, 54, 14, 0.5);
	}
}

.image-overlay {
	position: absolute;
	@extend .box-shadow-style-1;
	&--1 {
		top: 10rem;
	}
}

.main {
	&--full-width {
		position: relative;
		padding: 0;
		.wf-columns {
			gap: 0;
		}
	}
	&--colour {
		&-1,
		&-4 {
			* {
				color: $white;
			}
		}
		&-3 {
			p,
			li {
				font-weight: 500;
			}
		}
		&-1 {
			background-color: $primary-colour;
		}
		&-2 {
			background-color: $secondary-colour;
		}
		&-3 {
			background-color: $tertiary-colour;
		}
	}
}
